@import "../../css-config/mixins.scss";

.shop-by-category {
    margin: 25px 0;
    @include md {
        margin: 50px 0;
    }
    h1{
        text-align: center;
        color: rgba(0, 0, 0);
        font-size: 2rem;
        margin-bottom: 15px;
        font-weight: 500;
    }
    .categories {
        display: flex;
        flex-flow: wrap;
        gap: 10px;
        .category {
            background-color: black;
            width: calc(50% - 5px);
            cursor: pointer;
            overflow: hidden;
            @include md {
                width: calc(25% - 10px);
            }
            img {
                width: 100%;
                display: block;
                transition: all ease 0.3s;
            }
            &:hover {
                img{
                    transform: scale(1.2);
                }
            }
        }
    }
}
