@import "../../../css-config/mixins.scss";

.slider{
    width: 100vw;
    position: relative;
    overflow: hidden;
    @include md {
      width: 100%;
    }
  
    .container{
      width: 300vw;
      height: 100%;
      display: flex;
      transition: all 1s ease;
  
      img{
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .icons{
      width: fit-content;
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      gap: 10px;
      margin: auto;

      @include md {
        width: fit-content;
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        gap: 10px;
        margin: auto;
      }
  
      .icon{
          width: 25px;
          height: 21px;
          border: 1px solid #b77d7d;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

        @include md {
          width: 50px;
          height: 50px;
          border: 1px solid #999;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        svg{
          font-size: 20px;
          color: #b77d7d;
        }
      }
    }
  }